import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="PureSleep Homepage" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>UI/UX Redesign: PureSleep Homepage</h1>
          <p>Through the collaboration between Art Van departments, the PureSleep home page redesign approach resulted in a true omni-channel experience: customer beginning their mattress search on the website, educating themselves on what they need, and encouraging the customer to move their search into the store &ndash; immediately impacting the bedding customer journey through integration between online and in-store.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647952/hireallisonokeefe/puresleep-homepage/puresleep_01_ectscc.jpg" alt="" />
            <figcaption>Above: early PureSleep home page customer journey.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647953/hireallisonokeefe/puresleep-homepage/puresleep_02_lx6noc.jpg" alt="" />
            <figcaption>Above: initial pencil sketch of PureSleep wireframe.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>A main goal of the redesign was to bring people back to the moments that they spend in bed, and emphasize that 1/3 of our lives are spent in bed. The reality of it is purchasing a mattress can be:</p>
          <ol>
            <li>Intimidating &ndash; needing to buy a new mattress (or a mattress for the first time) is an overwhelming experience</li>
            <li>Expensive!</li>
          </ol>
          <p>However overwhelming and pricey, it is up to us as the experts to bring forth the value behind that cost. And that it’s important educate the customer early on in the mattress buying journey, arming them with knowledge to get them comfortable enough to visit a PureSleep store.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647954/hireallisonokeefe/puresleep-homepage/puresleep_03_mlizoc.jpg" alt="" />
            <figcaption>Above: more defined sketch of customer journey.</figcaption>
          </figure>
        </div>
        <div className="half">
          <p>To create a true omni-channel experience, having an understanding of how customers buy in the stores became a key part of the redesign. This was known as the Pure 7:</p>
          <ol>
            <li>Greet the customer at the door</li>
            <li>Conduct the diagnostic Rest Assured Test</li>
            <li>Fit the customer to a pillow before their mattress (pillow height contributing to the firmness of mattress required)</li>
            <li>Fit the customer to their mattress &ndash; Includes the customers comfort level between Extra Plush, Plush, Medium, Firm, Extra Firm</li>
            <li>Protect the customers investment with a mattress protector</li>
            <li>Set the customer up with a full sleep solution with the offer of an adjustable base</li>
            <li>Close the sale and create post sale retention</li>
          </ol>
        </div>
        <div className="full">
          <p>The result was a cohesive and engaging experience from online to store, complete with branding, creative assets, and usage of brand language.</p>
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647955/hireallisonokeefe/puresleep-homepage/puresleep_04_bwfjyz.png" alt="Redesigned PureSleep homepage on a desktop and mobile screen" />
          <div className="video-wrapper">
            <iframe title="Showcasing PureSleep homepage after project completion and launch" src="https://player.cloudinary.com/embed/?cloud_name=innocent&public_id=hireallisonokeefe%2Fpuresleep-homepage%2Fpuresleep-video_lwcfhk&fluid=true&controls=true&source_types%5B0%5D=mp4" allow="fullscreen; encrypted-media; picture-in-picture" allowFullScreen frameBorder="0" />
          </div>
          <p>Looking at the data over time is positive: with the launch in March 2019, over time we saw an increase in customer traffic, a decrease in transactions but an increase in the amount that customers were purchasing by close to $3,000.</p>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647952/hireallisonokeefe/puresleep-homepage/puresleep_05_mjsk4p.png" alt="" />
            <figcaption>Above: Google Analytics data of customer interactions on PureSleep homepage.</figcaption>
          </figure>
          <p><span className="text--bold">Programs used:</span> Sketch App, Photoshop</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
